import React, { useEffect, useState } from "react";
import { FaFacebookF, FaShareAlt } from "react-icons/fa";
import { FaXTwitter, FaInstagram } from "react-icons/fa6";
import "./singleBlog.css";
import DefaultBlogImage from "../../../assets/img/default-blog.jpg";
import DefaultAuthorImage from "../../../assets/img/author-img.jpg";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { useParams } from "react-router-dom";
import client, { urlFor } from "../../../client";
import PortableTextForBlog from "../../../components/resuseable_ui/portabletextforblogpage/PortableText";

const SingleBlog = () => {
  //! function To show the share social link button
  const shareUrl = window.location.href;
  const title = "Check this out!";
  const text = "Here is an interesting link I found:";
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: text,
          url: shareUrl,
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  //! To import the single blog scheme from the sanity.io studio
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchpost = async () => {
      const query = `
      *[_type == "post" && slug.current == $slug][0]{
        title,
        "category": category->title,
        body,
        publishedAt,
        "backgroundImage": backgroundImage.asset->url,
        "authorName": author->name,
        "authorImage": author->image.asset->url,
        header,
        paragraph,
        quote,
        smallHeader,
        smallParagraph,
       }
      `;
      const params = { slug };
      const result = await client.fetch(query, params);
      console.log("🚀 ~ fetchpost ~ result:", result);
      setPost(result);
    };
    fetchpost();
  }, [slug]);

  if (!post) return <div>Loading...</div>;
  return (
    <>
      <Header />
      <div className="main-blogBody">
        {/* //* For single blog header */}
        <div className="singleBlog-head">
          <h1>{post.title}</h1>
          <p>{new Date(post.publishedAt).toLocaleDateString()}</p>
        </div>
        {/* //* blog image and author name*/}
        <div className="single-blogimgCont">
          <img
            src={
              post.backgroundImage
                ? urlFor(post.backgroundImage).url()
                : DefaultBlogImage
            }
            alt={post.title}
          />

          <div>
            <img
              src={
                post.authorImage
                  ? urlFor(post.authorImage).url()
                  : DefaultAuthorImage
              }
              alt={post.title}
            />
            <h4>{post.authorName} </h4>
          </div>
        </div>
        {/* //* The main Blog body */}
        <div className="single-blogDetailCont">
          {/* //*social media */}
          <div className="single-sociShareCont">
            <h2>Follow us & Share</h2>
            <div className="single-blogSocial">
              <a
                href="https://www.facebook.com/PrestonAcademyofEnglish/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://twitter.com/PrestonAcademy2"
                target="_blank"
                rel="noreferrer"
              >
                <FaXTwitter />
              </a>
              <a
                href="https://www.instagram.com/prestonacademy?igsh=MXB1MnRobzA5ZHBvMw=="
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
              <div onClick={handleShare}>
                <FaShareAlt />
              </div>
              {/* //TODO: Tell Abduallah the like and dislike need to be when the user is logged in some how to our website but will still find another way later */}
              {/* Like and Dislike Buttons */}

              {/* <button onClick={handleLike}>Like ({post.likes})</button>
                <button onClick={handleDislike}>
                  Dislike ({post.dislikes})
                </button> */}
            </div>
          </div>

          {/* //* For the blog detailed body */}
          <div className="single-blogMainDetailCont">
            {post.header?.enabled && <h1>{post.header.text}</h1>}

            <div>
              {post.body.map((block, index) => (
                <PortableTextForBlog key={index} value={block} />
              ))}
            </div>
            {/* //* for any important Quote */}
            <div>{post.quote?.enabled && <h2>{post.quote.text}</h2>}</div>
            {post.paragraph?.enabled && <p>{post.paragraph.text}</p>}
            {/* //* used if there's a pin point for topics */}
            <div>
              {post.smallHeader?.enabled && <h4>{post.smallHeader.text}</h4>}
              {post.smallParagraph?.enabled && (
                <p>{post.smallParagraph.text}</p>
              )}
            </div>
          </div>
        </div>
        {/* //TODO: Don't forget to add other recommended articles below */}
      </div>
      <Footer />
    </>
  );
};

export default SingleBlog;
