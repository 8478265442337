import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { NavLink } from "react-router-dom";
import DefaultBlogImage from "../../assets/img/default-blog.jpg";
import client from "../../client";
import "./blog.css";
import BlogSEO from "../../components/SEO/BlogSEO";

const Blog = () => {
  const [allpostsData, setAllPostData] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const query = `*[_type == "post"]{
        title,
        "category": category->title,
        body,
        publishedAt,
        "backgroundImage": backgroundImage.asset->url,
        slug
      }`;
      const result = await client.fetch(query);
      setAllPostData(result);
    };
    fetchPosts();
  }, []);

  return (
    <>
      <BlogSEO />
      <Header />
      <div className="blog-MainCont">
        {/* //* Title of the blog page */}
        <div className="blog-head">
          <h1>Blog Articles</h1>
          <p>
            Blog articles about Preston, Events, Latest news and lots more ....
          </p>
        </div>
        {/* //Todo: Don't forget to Add categories to this file for users to search news or blogs based on categories */}
        {/* //*Body of the blog */}
        <div className="blog-detailCont">
          {/* //*Each blog container */}

          {allpostsData.length > 0 ? (
            allpostsData.map((post) => (
              <div className="single-blogCont">
                <img
                  src={
                    post.backgroundImage
                      ? post.backgroundImage
                      : DefaultBlogImage
                  }
                  alt="blog pic"
                />
                <div className="small-blogDetail">
                  <h3>{post.category}</h3>
                  <h2>{post.title}</h2>
                  <div>
                    <p className="small-blogDetailPara">
                      {post.body
                        .map((block) => {
                          if (block._type === "block") {
                            return block.children
                              ?.map((child) => child.text)
                              .join("");
                          }
                          return "";
                        })
                        .join("") || "No description"}
                    </p>
                  </div>

                  <div className="blog-date">
                    <p>{new Date(post.publishedAt).toLocaleDateString()}</p>
                    <NavLink
                      className="blog-btn"
                      to={`/blog/${post.slug.current}`}
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <h1>There's no post yet, Coming Soon......</h1>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
