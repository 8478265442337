import React from "react";
import { Helmet } from "react-helmet";

const StudAccodSEO = () => {
  return (
    <Helmet>
      <title>Student Accommodation - Preston Academy of English</title>
      <meta
        name="description"
        content="Find comfortable and convenient student accommodation at Preston Academy of English. Our centrally-located residences offer en-suite rooms, 24-hour security, and easy access to local facilities and the academy."
      />
      <meta
        name="keywords"
        content="student accommodation, Preston Academy of English, en-suite rooms, student residence, 24-hour security, centrally-located, Preston student housing"
      />
    </Helmet>
  );
};

export default StudAccodSEO;
