import React from "react";
import "./price.css";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import FullTime from "../../../assets/files/PAE Full Time .pdf";
import PartTime from "../../../assets/files/PAE Part Time .pdf";
import PriceListSEO from "../../../components/SEO/PriceListSEO";

const PriceList = () => {
  return (
    <>
      <PriceListSEO />
      <Header />
      <div className="price-cont">
        {/* <a
          className="coursePrice-btn"
          target="_blank"
          rel="noreferrer"
          href="//www.coursepricer.com/coursepricer/?school_id=1508"
        >
          Get a Price
        </a> */}
        <iframe
          className="iframe"
          src="//www.coursepricer.com/coursepricer/?school_id=1508"
          title="course-pricer"
          allowFullScreen
        >
          Get a price
        </iframe>
        <div className="priceBtn-cont">
          <a className="price-Btn" download href={FullTime}>
            Download full-Time price
          </a>
          <a className="price-Btn" download href={PartTime}>
            Download part-Time price
          </a>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PriceList;
