import React from "react";
import { Helmet } from "react-helmet";

const EnglishExamsSEO = () => {
  return (
    <Helmet>
      <title>UKVI English Exams - Preston Academy of English</title>
      <meta
        name="description"
        content="Prepare for your UKVI English exams at Preston Academy of English. We offer A1, A2, and B1 tests for visa and citizenship purposes, approved by the UK Home Office. Get expert guidance and achieve your goals."
      />
      <meta
        name="keywords"
        content="UKVI English exams, A1 test, A2 test, B1 test, visa preparation, citizenship test, UK Home Office approved, Preston Academy of English"
      />
    </Helmet>
  );
};

export default EnglishExamsSEO;
