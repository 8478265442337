import React from "react";
import { Helmet } from "react-helmet";

const BlogSEO = () => {
  return (
    <Helmet>
      <title>Blog - Preston Academy of English</title>
      <meta
        name="description"
        content="Stay updated with the latest news, tips, and insights on learning English at Preston Academy of English. Our blog covers a wide range of topics to help you improve your English skills and stay informed about our courses and events."
      />
      <meta
        name="keywords"
        content="English learning blog, Preston Academy of English blog, English language tips, IELTS preparation tips, English courses updates, language learning insights"
      />
    </Helmet>
  );
};

export default BlogSEO;
