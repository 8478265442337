import React from "react";
import { Helmet } from "react-helmet";

const CoursesSEO = () => {
  return (
    <Helmet>
      <title>Courses at Preston Academy of English</title>
      <meta
        name="description"
        content="Explore a variety of English language courses at Preston Academy of English, including General English, IELTS preparation, Speaking Skills, 1-to-1 courses, and UKVI SELT test preparation. Join us to enhance your English proficiency with expert guidance and personalized learning."
      />
      <meta
        name="keywords"
        content="General English, IELTS Preparation, Speaking Skills, 1-to-1 Courses, UKVI SELT Test, English Language Learning, Preston Academy of English"
      />
    </Helmet>
  );
};

export default CoursesSEO;
