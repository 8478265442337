import React from "react";
import { Helmet } from "react-helmet";

const PriceListSEO = () => {
  return (
    <Helmet>
      <title>Price List - Preston Academy of English</title>
      <meta
        name="description"
        content="Check out the comprehensive price list for various English language courses at Preston Academy of English. Find the best course that suits your needs and budget, including General English, IELTS preparation, and more."
      />
      <meta
        name="keywords"
        content="English language courses prices, General English prices, IELTS preparation prices, Preston Academy of English, course fees, English learning costs"
      />
    </Helmet>
  );
};

export default PriceListSEO;
