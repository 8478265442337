import React from "react";
import { Helmet } from "react-helmet";

const HomeSEO = () => {
  return (
    <Helmet>
      <title>Preston Academy of English - Learn English in Preston</title>
      <meta
        name="description"
        content="Join Preston Academy of English for top-notch English language courses, IELTS preparation, and General English classes. Accredited by the British Council and Trinity College London, we offer small class sizes and an online learning suite with 24/7 support."
      />
      <meta
        name="keywords"
        content="English language courses, IELTS preparation, General English, Preston Academy, English learning, British Council accredited, Trinity College London, Small class sizes, Online learning suite, 24/7 support"
      />
    </Helmet>
  );
};

export default HomeSEO;
